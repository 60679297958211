<template>
  <div class="myProfile-box scroll-box">
    <table>
      <tr>
        <td><img src="@/assets/images/myProfile/accountType.png" alt="" />{{ $t('common.field.accType') }}</td>
        <td>{{ accountType[clientProfileData.accountType] }}</td>
      </tr>
      <br />
      <tr>
        <td><img src="@/assets/images/myProfile/name.png" alt="" />{{ $t('common.field.name') }}</td>
        <td>{{ clientProfileData.name }}</td>
      </tr>
      <br />
      <tr>
        <td><img src="@/assets/images/myProfile/address.png" alt="" />{{ $t('common.field.emailAdd') }}</td>
        <td>{{ clientProfileData.emailAddress }}</td>
      </tr>
      <br />
      <tr>
        <td><img src="@/assets/images/myProfile/phone.png" alt="" />{{ $t('common.field.phone') }}</td>
        <td>{{ clientProfileData.phone }}</td>
      </tr>
      <br />
      <tr>
        <td><img src="@/assets/images/myProfile/nationality.png" alt="" />{{ $t('common.field.nat') }}</td>
        <td>{{ clientProfileData.nationality }}</td>
      </tr>
      <br />
      <tr>
        <td><img src="@/assets/images/myProfile/country.png" alt="" />{{ $t('common.field.country') }}</td>
        <td>{{ clientProfileData.country }}</td>
      </tr>
      <br />
      <tr>
        <td><img src="@/assets/images/myProfile/address.png" alt="" />{{ $t('common.field.address') }}</td>
        <td>{{ clientProfileData.streetAddress }}</td>
      </tr>
      <br />
      <tr>
        <td><img src="@/assets/images/myProfile/saving.png" alt="" />{{ $t('common.field.save') }}</td>
        <td>{{ clientProfileData.savingsInvestment }}</td>
      </tr>
      <br />
      <tr>
        <td><img src="@/assets/images/myProfile/register.png" alt="" />{{ $t('common.field.annIncome') }}</td>
        <td>{{ clientProfileData.averageIncome }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import myProfileMixin from '@/mixins/page/myProfile';

export default {
  mixins: [myProfileMixin],
  data() {
    return {
      clientProfileData: {
        accountType: '',
        name: '',
        emailAddress: '',
        phone: '',
        dateBirth: '',
        nationality: '',
        country: '',
        streetAddress: '',
        suburb: '',
        state: '',
        postcode: '',
        employmentStatus: '',
        averageIncome: '',
        savingsInvestment: '',
        sourceOfFunds: '',
        ipChangeWarn: ''
      }
    };
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/myProfile.scss';
</style>
