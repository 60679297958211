import { apiInfo } from '@/resource'

export default {
  data() {
    return {
      accountType: {
        1: 'Demo Account',
        2: 'Personal Account',
        3: 'Master Joint Account',
        4: 'Vice Joint Account',
        5: 'IB Individual Account',
        6: 'Internal Account',
        7: 'Experience Account',
        8: 'Leads Account',
        9: 'Repetitive Leads Account',
        10: 'Imported Leads Account',
        11: 'Irregular Leads Account',
        13: 'Company Account',
        14: 'SMSF Account',
        15: 'Vice Leads Account',
        16: 'IB Company Account',
        17: 'Migration Leads Account',
        18: 'Migration Demo Account',
        19: 'Conflict Account',
      },
    }
  },
  mounted() {
    apiInfo().then(resp => {
      if (resp.data.code == 0) this.clientProfileData = resp.data.data
    })
  },
}
